import { Box } from "@mui/material";

   
   
   
const Handshake = (props) => (   
    <Box {...props}>
   <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100" id="1400141699" class="svg u_1400141699" alt="a black and white drawing of two hands shaking each other ." data-icon-name="business_handshake"> <path d="M63.42,55.25c-6.49,0-10.87-6.35-10.93-6.44-.53-.73-1.36-1.61-2.2-1.55s-1.66,1.21-1.86,1.6a6.29,6.29,0,0,1-3.3,3.78,4.43,4.43,0,0,1-3.58-.29,2.61,2.61,0,0,1-1.33-3.09l1.11-3.8a6.76,6.76,0,0,1,3.78-4.31l4.18-1.84a8.16,8.16,0,0,1,5.27-.19l11.37,2.69,4.64,10.84-.63.42A11.57,11.57,0,0,1,63.42,55.25Zm-13-9.81c1.25,0,2.46.78,3.59,2.32.28.4,6.23,9,14.3,4.21L64.61,43.4,54.16,40.92c-1.79-.36-2.93-.46-4.14.08l-4.18,1.84A4.94,4.94,0,0,0,43.08,46L42,49.78a.8.8,0,0,0,.38.94,2.64,2.64,0,0,0,2.14.22,4.71,4.71,0,0,0,2.29-2.84c.16-.31,1.32-2.49,3.36-2.66Z"></path> 
 <path d="M64.2,61.07l-6.74-9.4,2.95,1.22c.19.08,4.8,1.9,8.43-1.24l.94-.82L73,58.28Zm-2-5.86,2.67,3.73,5.59-1.78L69,53.77A9.85,9.85,0,0,1,62.23,55.2Z"></path> 
 <path d="M16.6,64.24,8.81,61.16V26.61l19.89,8.06Zm-6-4.32,5,2L26.33,35.67,10.63,29.31Z"></path> 
 <path d="M28,58.88l-8.78-3.42a.91.91,0,0,1,.66-1.7L27,56.55l6.51-15.21-7.29-3.07a.91.91,0,0,1,.71-1.68l9,3.78Z"></path> 
 <path d="M83.4,66,71.29,36.44l19.89-8.06V62.92ZM73.67,37.44,84.41,63.65l5-2V31.07Z"></path> 
 <path d="M72,60.65,64.07,42.13l9-3.78A.91.91,0,1,1,73.76,40L66.47,43.1,73,58.31l7.14-2.78a.91.91,0,1,1,.66,1.7Z"></path> 
 <path d="M63.68,66.15a3.4,3.4,0,0,1-2.48-1.08l-8.5-9A.91.91,0,1,1,54,54.81l8.5,9a1.61,1.61,0,0,0,2.68-.57A1.29,1.29,0,0,0,65,62l-6.19-7.76a.91.91,0,0,1,1.42-1.14l6.19,7.76a3.1,3.1,0,0,1,.5,3,3.4,3.4,0,0,1-2.52,2.21A3.46,3.46,0,0,1,63.68,66.15Z"></path> 
 <path d="M59.44,69.21a4.6,4.6,0,0,1-3.34-1.44L49,60.3A.91.91,0,1,1,50.27,59l7.15,7.48a2.81,2.81,0,0,0,2.68.8,2.71,2.71,0,0,0,2-1.82l0-.11a.91.91,0,0,1,1.74.53l0,.13a4.52,4.52,0,0,1-3.32,3A4.76,4.76,0,0,1,59.44,69.21Z"></path> 
 <path d="M54.84,72h-.25a4.62,4.62,0,0,1-3.17-1.58L45.51,63.8a.91.91,0,0,1,1.35-1.22l5.91,6.57a2.86,2.86,0,0,0,1.92,1,3.21,3.21,0,0,0,2.89-1.88.91.91,0,0,1,1.55.95A5,5,0,0,1,54.84,72Z"></path> 
 <path d="M46.55,42.44l-.2,0a44.87,44.87,0,0,0-11.54-.66.91.91,0,0,1-.15-1.82,46.08,46.08,0,0,1,12.1.7.91.91,0,0,1-.2,1.8Z"></path> 
 <path d="M50.32,73.39A3.35,3.35,0,0,1,48,72.45l-2.77-2.66a147.27,147.27,0,0,0-17-14.28.91.91,0,1,1,1-1.5A149.11,149.11,0,0,1,46.49,68.49l2.76,2.65a1.53,1.53,0,0,0,1.57.35,1.4,1.4,0,0,0,1-1.12l.91.07.9.15-.91-.07.9.14a3.2,3.2,0,0,1-2.19,2.56A3.41,3.41,0,0,1,50.32,73.39Z"></path> 
 <path d="M34.28,63.18a3,3,0,0,1-1.9-.68,2.79,2.79,0,0,1-.31-4l.73-.81a.91.91,0,1,1,1.36,1.21l-.73.81a1,1,0,0,0,.11,1.39A1.17,1.17,0,0,0,35.15,61l.71-.8a.91.91,0,1,1,1.36,1.21l-.71.8A3,3,0,0,1,34.28,63.18Z"></path> 
 <path d="M37,67.4A3.24,3.24,0,0,1,34.55,62L36,60.32a.91.91,0,1,1,1.38,1.19l-1.49,1.72A1.42,1.42,0,0,0,38,65.11l1.6-1.74A.91.91,0,1,1,41,64.6l-1.6,1.74A3.23,3.23,0,0,1,37,67.4Z"></path> 
 <path d="M41,69.9a3.22,3.22,0,0,1-2.37-5.4l1-1.13A.91.91,0,1,1,41,64.6l-1,1.13A1.4,1.4,0,0,0,42,67.64l1.08-1.15a.91.91,0,1,1,1.33,1.25l-1.08,1.15A3.22,3.22,0,0,1,41,69.9Z"></path> 
 <path d="M44.78,72.3a3.09,3.09,0,0,1-2.25-5.21l.41-.43a.91.91,0,0,1,1.33,1.25l-.41.43a1.27,1.27,0,0,0,1.84,1.76l.57-.58a.91.91,0,1,1,1.3,1.27l-.57.58A3.09,3.09,0,0,1,44.78,72.3Z"></path> 
</svg></Box>)
export default Handshake;